import React, {
	useCallback,
	useEffect,
	useState,
	useContext,
	Children,
} from "react";
import Button from "../common/button/button";
import Divider from "../common/divider/divider";
import Underlayer from "../common/underlayer/underlayer";
import styles from "./filter-mobile.module.scss";
import { StaticContext } from "../../context/static-context/static-context";
import FilterRadio from "../filter-sidebar/filter-radio";
import FilterMobileItem from "./filter-mobile-item";
import FilterMobileCategories from "./filter-mobile-categories";
import FilterMinValue from "../filter-sidebar/filter-minValue";
import FilterCost from "../filter-sidebar/filter-cost";
import FilterMobileDelivery from "./filter-mobile-delivery";
import FilterCheckboxes from "../filter-sidebar/filter-checkboxes";
import { ListingStatusEnum } from "../../graphql/types";
import InventoryPage, {
	getParametersFromRouter,
	ISearchState,
} from "../../../pages/inventory/InventoryPage";
import useWindowSize from "../../hooks/useWindowSize";
import { useRouter } from "next/router";
import lodash from "lodash";

interface FilterMobileProps {
	isUserItems?: boolean;
	thirdParty?: boolean;
	seo?: boolean;
	retailerId?: string;
	seoCategories?: boolean;
	seoRetailers?: boolean;
	categoriesID?: string;
	hidden?: boolean;
	showBurgerMenu?: boolean;
}

function FilterMobile({
	isUserItems,
	seo,
	hidden = true,
	thirdParty,
	retailerId,
	categoriesID,
	seoRetailers = false,
	seoCategories = false,
	showBurgerMenu,
}: FilterMobileProps): JSX.Element {
	const contextData = useContext(StaticContext);
	const { isHidden, setIsHidden } = contextData;

	const [isMobile, setIsMobile] = useState(false);
	const { width } = useWindowSize();

	useEffect(() => {
		const isMobileWidth = () => (width <= 600 ? true : false);
		setIsMobile(isMobileWidth);
	}, [setIsMobile, width]);

	const router = useRouter();
	const query = router.query;
	const searchParameters = getParametersFromRouter(router);
	const [searchState, setSearchState] = useState<ISearchState>(
		searchParameters,
	);

	const [searchStateConst, setSearchStateConst] = useState<ISearchState>(
		searchParameters,
	);

	const [searchTimeoutRout, setSearchTimeoutRout] = useState<
		NodeJS.Timeout | undefined
	>(undefined);

	const updateSearchState = (p: Partial<ISearchState>) => {
		setSearchState({
			...searchState,
			...p,
		});
	};

	const handleInventoryLoadCategoryChange = (value: string) => {
		let newVal = searchState.category || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			category: Array.from(new Set(newVal)),
		});
	};

	const handleDeleteCategory = (values: string[]) => {
		const newVal = searchState.category.filter((category: string) => {
			return values.indexOf(category) == -1;
		});
		updateSearchState({
			category: [...newVal],
		});
	};

	const handleRetailerChange = (value: string) => {
		let newVal = searchState.retailer || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			retailer: Array.from(new Set(newVal)),
		});
	};

	const handleDeleteOfRetailer = (value: string) => {
		const newVal = searchState.retailer.filter((retailer: string) => {
			return retailer != value;
		});
		updateSearchState({
			retailer: [...newVal],
		});
	};

	const handleConditionChange = (value: string) => {
		let newVal = searchState.loadCondition || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			loadCondition: Array.from(new Set(newVal)),
		});
	};
	const handleDeleteOfCondition = (values: string[]) => {
		const newVal = searchState.loadCondition.filter((condition: string) => {
			return values.indexOf(condition) == -1;
		});
		updateSearchState({
			loadCondition: [...newVal],
		});
	};

	const handleIdealUseChange = (value: any) => {
		let newVal = searchState.listingIdealUse || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			listingIdealUse: Array.from(new Set(newVal)),
		});
	};
	const handleIdealUse = (values: string[]) => {
		const newVal = searchState.listingIdealUse.filter((condition: string) => {
			return values.indexOf(condition) == -1;
		});
		updateSearchState({
			listingIdealUse: [...newVal],
		});
	};

	const handleSizeChange = (value: string) => {
		let newVal = searchState.size || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			size: Array.from(new Set(newVal)),
		});
	};
	const handleDeleteOfSize = (values: string[]) => {
		const newVal = searchState.size.filter((condition: string) => {
			return values.indexOf(condition) == -1;
		});
		updateSearchState({
			size: [...newVal],
		});
	};

	const handleRegionAdd = (value: string) => {
		let newVal = searchState.regions || [];
		if (value !== "") {
			newVal.push(value);
		}
		updateSearchState({
			regions: Array.from(new Set(newVal)),
		});
	};

	const handleRegionDelete = (value: string) => {
		const newVal = searchState.regions.filter((region: string) => {
			return region !== value;
		});
		updateSearchState({
			regions: [...newVal],
		});
	};

	const handleStateAdd = (value: string) => {
		let newVal = searchState.states || [];

		if (value !== "") {
			newVal.push(value);
		}

		updateSearchState({
			states: Array.from(new Set(newVal)),
		});
	};

	const handleStateDelete = (value: string) => {
		const newVal = searchState.states.filter((state: string) => {
			return state !== value;
		});
		updateSearchState({
			states: [...newVal],
		});
	};

	const listingStatusChangeSelection = (
		checkedStatues: ListingStatusEnum[],
	) => {
		updateSearchState({
			listingStatus: [...checkedStatues],
		});
	};

	useEffect(() => {
		const queryParameters = getParametersFromRouter(router);
		setSearchState((s) => queryParameters);
	}, [router, router.asPath]);

	const onFormSubmit = (e: any) => {
		e.preventDefault();

		const searchIsEqual = lodash.isEqual(searchParameters, searchState);

		const newQuery: any = {
			...query,
			...searchState,
			skip: searchIsEqual ? searchParameters.skip : 0,
			take: searchParameters.take,
		};

		for (let key in newQuery) {
			if (newQuery[key] === undefined || newQuery[key] === null) {
				delete newQuery[key];
			} else if (newQuery[key].length === 0) {
				delete newQuery[key];
			} else if (newQuery[key] === "") {
				delete newQuery[key];
			}
		}

		router.push(
			{
				pathname: "/inventory",
				query: newQuery,
			},
			undefined,
			{ scroll: false },
		);
	};

	const toggleFilterPanel = useCallback(() => {
		setIsHidden((value) => !value);
	}, [setIsHidden]);

	console.log("render mob filter");

	return (
		<>
			<Underlayer isHidden={isHidden} />
			<div
				className={`
					${styles.mobileFilter}
					${isHidden ? styles.hidden : ""}
					`}
			>
				<div className={styles.filterPanel}>
					<div className={styles.innerWrapper}>
						<div className={`${styles.filterMobileContent}`}>
							<div style={{ padding: "10px 10px 60px 10px" }}>
								<FilterMobileItem
									title={"Categories"}
									typeFilter="Category"
									toggle={false}
									handleInventoryLoadCategoryChange={
										handleInventoryLoadCategoryChange
									}
									handleDeleteCategory={handleDeleteCategory}
									classNameCss={true}
								/>
								<FilterMobileItem
									title={"Product Condition"}
									typeFilter="InventoryLoadCodition"
									toggle={false}
									handleConditionChange={handleConditionChange}
									handleDeleteOfCondition={handleDeleteOfCondition}
									classNameCss={true}
								/>

								<FilterMobileItem
									title={"Lot Size"}
									typeFilter="InventoryIdealUse"
									toggle={false}
									handleIdealUseChange={handleIdealUseChange}
									handleDeleteIdealUse={handleIdealUse}
								/>
								<FilterMobileItem
									title={"Retailers"}
									typeFilter="Retailers"
									toggle={true}
									handleRetailerChange={handleRetailerChange}
									handleDeleteOfRetailer={handleDeleteOfRetailer}
								/>
								<FilterMobileItem
									title={"State"}
									typeFilter="State"
									toggle={true}
									handleStateAdd={handleStateAdd}
									handleValueDeleteState={handleStateDelete}
								/>
								<FilterMobileItem
									title={"Region"}
									typeFilter="Region"
									toggle={false}
									handleRegionAdd={handleRegionAdd}
									handleRegionDelete={handleRegionDelete}
								/>
								<FilterMobileDelivery
									handleChangeDelivery={(value: string) => {
										updateSearchState({
											deliveryType: value,
										});
									}}
								/>
								<div className={styles.headerWrapper}>
									<div>Minimum Value</div>
									<Divider />
								</div>
								<FilterMinValue
									handleChange={(value: number) => {
										updateSearchState({
											minValue: value || undefined,
										});
									}}
								/>
								<div className={styles.headerWrapper}>
									<div>Cost</div>
									<Divider />
								</div>
								<div className={styles.filterCost}>
									<FilterCost
										onChangeFrom={(value: string) => {
											updateSearchState({
												currentPriceFrom: value,
											});
										}}
										onChangeTo={(value: string) => {
											updateSearchState({
												currentPriceTo: value,
											});
										}}
									/>
								</div>
								<div className={styles.headerWrapper}>
									<div>Available for Export</div>
									<Divider />
								</div>
								<FilterRadio
									title="Available for Export"
									isFirstRadioChecked={searchState.availableForExport}
									onChangeValue={(value: boolean | undefined) => {
										updateSearchState({
											availableForExport: value,
										});
									}}
								/>
								<div className={styles.headerWrapper}>
									<div>Available for Brokering</div>
									<Divider />
								</div>
								<FilterRadio
									title="Available for Brokering"
									isFirstRadioChecked={searchState.canBeSoldByThirdParty}
									onChangeValue={(value: boolean | undefined) => {
										updateSearchState({
											canBeSoldByThirdParty: value,
										});
									}}
								/>
								{thirdParty ? (
									<FilterCheckboxes
										title="Listing Status"
										selectedValues={searchState.listingStatus}
										onChangeValue={listingStatusChangeSelection}
										values={[
											{
												title: "Draft",
												value: ListingStatusEnum.Draft,
											},
											{
												title: "Published",
												value: ListingStatusEnum.Active,
											},
											{
												title: "Sold",
												value: ListingStatusEnum.Sold,
											},
										]}
									/>
								) : null}
							</div>
							<div
								onClick={toggleFilterPanel}
								className={styles.showResultsBtnBox}
							>
								<Button
									className={styles.showResultsBtn}
									title={"Return to Results"}
									onClick={onFormSubmit}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FilterMobile;

import React, { useCallback, useEffect, useState, useContext } from "react";
import styles from "./filter-mobile.module.scss";
import { StaticContext } from "../../context/static-context/static-context";
import Divider from "../common/divider/divider";
import BadgeMobile from "../common/badge-mobile/badge-mobile";

import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";
import { deliveryTypes } from "../translations/delivery-types";
import {
	idealUseBadge,
	idealUseID,
} from "../filter-sidebar/filter-idealUse/filter-modal-condition";

interface FilterMobileProps {
	typeFilter?:
		| "InventoryLoadSize"
		| "State"
		| "Retailers"
		| "Regions"
		| "InventoryLoadCodition"
		| "DeliveryType"
		| "InventoryIdealUse"
		| "Region"
		| "Category";
	title?: string;
	toggle?: boolean;
	handleConditionChange?: (value: string) => void;
	handleDeleteOfCondition?: (value: string[]) => void;
	handleIdealUseChange?: (value: string) => void;
	handleDeleteIdealUse?: (value: string[]) => void;
	handleSizeChange?: (value: string) => void;
	handleDeleteOfSize?: (value: string[]) => void;
	handleRetailerChange?: (value: string) => void;
	handleDeleteOfRetailer?: (value: string) => void;
	handleRegionAdd?: (value: string) => void;
	handleRegionDelete?: (value: string) => void;
	handleStateAdd?: (value: string) => void;
	handleValueDeleteState?: (value: string) => void;
	classNameCss?: boolean;
	seo?: boolean;
	handleInventoryLoadCategoryChange?: (value: string) => void;
	handleDeleteCategory?: (value: string[]) => void;
}

function FilterMobileItem({
	title,
	typeFilter,
	toggle,
	handleConditionChange,
	handleDeleteOfCondition,
	handleSizeChange,
	handleDeleteOfSize,
	handleRetailerChange,
	handleDeleteOfRetailer,
	handleRegionAdd,
	handleRegionDelete,
	handleStateAdd,
	handleValueDeleteState,
	handleIdealUseChange,
	handleDeleteIdealUse,
	classNameCss = false,
	seo = false,
	handleInventoryLoadCategoryChange,
	handleDeleteCategory,
}: FilterMobileProps): JSX.Element {
	const contextData = useContext(StaticContext);
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [array, setArray] = useState<any[]>([]);
	const [targetArray, setTargetArray] = useState<any[]>([]);

	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	useEffect(() => {
		if (typeFilter === "Category") {
			const result = contextData.categories.filter(
				(ele) => ele?.parent?.id === "0e46c6e5-b0cc-4cfe-b3ff-08e7b9af7aa3",
			);
			setArray([...result]);
		}
		if (typeFilter === "DeliveryType") {
			const result = [...deliveryTypes].map((ele: any) => ({
				...ele,
				id: ele.value,
			}));
			setArray([...result]);
		}
		if (typeFilter === "Retailers") setArray([...contextData.retailers]);
		if (typeFilter === "State") setArray([...contextData.states]);
		if (typeFilter === "Region") {
			const result = [...contextData.regions].map((ele: any) => ({
				...ele,
				id: ele.title,
			}));
			setArray([...result]);
		}

		// if(typeFilter === "Region"){
		// 	setArray([...contextData.regions])
		// }
		if (typeFilter === "InventoryLoadSize")
			setArray([
				...contextData.vocabularyEntries.filter(
					(ele) => ele.parent?.id === "7adf5b43-11d7-4559-9da2-4e77e66de949",
				),
			]);
		if (typeFilter === "InventoryLoadCodition") {
			let result: any = [];
			result = contextData.vocabularyEntries.filter(
				(ele) => ele.parent?.id === "74bde057-97f0-40c7-9718-ccd970b8337d",
			);

			const listCondition: any = [
				result[3],
				result[4],
				result[5],
				result[0],
				result[2],
				result[6],
				result[7],
				result[1],
			];

			setArray(listCondition);
		}

		if (typeFilter === "InventoryIdealUse") setArray([...idealUseID]);
	}, [contextData, typeFilter]);

	const addArray = (e: any): void => {
		const result = targetArray.map((ele) => (ele = ele.id));
		if (result.includes(e.id)) return;

		if (typeFilter === "DeliveryType") {
			if (targetArray) return;
		}

		setTargetArray((prev: any) => [...prev, e]);

		if (typeFilter === "InventoryLoadCodition") {
			handleConditionChange && handleConditionChange(e.id);
		}

		if (typeFilter === "InventoryIdealUse") {
			handleIdealUseChange && handleIdealUseChange(e.id);
		}

		if (typeFilter === "InventoryLoadSize") {
			handleSizeChange && handleSizeChange(e.id);
		}

		if (typeFilter === "Retailers") {
			handleRetailerChange && handleRetailerChange(e.id);
		}

		if (typeFilter === "Region") {
			handleRegionAdd && handleRegionAdd(e.value);
		}

		if (typeFilter === "State") {
			handleStateAdd && handleStateAdd(e.abbreviation);
		}

		if (typeFilter === "Category") {
			handleInventoryLoadCategoryChange &&
				handleInventoryLoadCategoryChange(e.id);
		}
	};

	const deleteArray = (e: any) => {
		if (typeFilter === "Region") {
			handleRegionDelete && handleRegionDelete(e.value);
			return;
		}

		let result = targetArray.filter((ele: any) => ele !== e);
		setTargetArray(result);

		if (typeFilter === "InventoryLoadCodition") {
			handleDeleteOfCondition && handleDeleteOfCondition(e.id);
		}

		if (typeFilter === "InventoryIdealUse") {
			handleDeleteIdealUse && handleDeleteIdealUse(e.id);
		}

		if (typeFilter === "InventoryLoadSize") {
			handleDeleteOfSize && handleDeleteOfSize(e.id);
		}

		if (typeFilter === "Retailers") {
			handleDeleteOfRetailer && handleDeleteOfRetailer(e.id);
		}

		if (typeFilter === "State") {
			handleValueDeleteState && handleValueDeleteState(e.abbreviation);
		}

		if (typeFilter === "Category") {
			handleDeleteCategory && handleDeleteCategory(e.id);
		}
	};

	useEffect(() => {
		let result: any = [];

		if (typeFilter === "InventoryLoadCodition") {
			queryParameters?.loadCondition.forEach((ele: any) => {
				result.push(contextData.getVocabularyEntry(ele));
			});
			setTargetArray(result);
		}

		if (typeFilter === "InventoryLoadSize") {
			queryParameters?.size.forEach((ele: any) => {
				result.push(contextData.getVocabularyEntry(ele));
			});
			setTargetArray(result);
		}

		if (typeFilter === "InventoryIdealUse") {
			for (let i = 0; i < queryParameters.listingIdealUse.length; i++) {
				result.push({
					id: queryParameters.listingIdealUse[i],
					title: idealUseBadge[queryParameters.listingIdealUse[i]],
				});
			}
			setTargetArray(result);
		}

		if (typeFilter === "Retailers") {
			for (let i in queryParameters.retailer) {
				let target = contextData.retailers.filter(
					(ele) => ele.id === queryParameters.retailer[i],
				);
				result.push(target[0]);
			}
			setTargetArray(result);
		}

		if (typeFilter === "Region") {
			for (let i in queryParameters.regions) {
				let target = {
					value: queryParameters.regions[i],
					title: queryParameters.regions[i],
					id: queryParameters.regions[i],
				};
				result.push(target);
			}
			setTargetArray(result);
		}

		if (typeFilter === "State") {
			for (let i in queryParameters.states) {
				let target = contextData.states.filter(
					(ele) => ele.abbreviation === queryParameters.states[i],
				);
				result.push(target[0]);
			}
			setTargetArray(result);
		}
	}, [router, contextData]);

	return (
		<>
			<div className={styles.filterSection}>
				{toggle ? (
					<>
						<div
							className={styles.headerWrapper}
							onClick={() => setIsVisible(!isVisible)}
						>
							<div>{title}</div>
							<Divider />
							<span
								className={`${styles.headerWrapperToggler} ${
									isVisible ? styles.isHidden : ""
								}`}
							></span>
						</div>

						{!isVisible && (
							<div className={styles.badgesWrapper}>
								{targetArray.map((ele: any, i: any) => (
									<div key={ele?.id || i}>
										<BadgeMobile
											title={`${ele?.code || ele?.name || ele?.title}`}
											isVisible={true}
											deleteArray={deleteArray}
											ele={ele}
											id={ele?.id || i}
											array={targetArray}
										/>
									</div>
								))}
							</div>
						)}
						<div className={styles.badgesWrapper}>
							{array.map((ele: any, i: any) => (
								<div key={ele?.id || i}>
									<BadgeMobile
										title={`${ele?.code || ele?.name || ele?.title}`}
										isVisible={isVisible}
										addArray={addArray}
										deleteArray={deleteArray}
										ele={ele}
										id={ele?.id || i}
										array={targetArray}
									/>
								</div>
							))}
						</div>
					</>
				) : (
					<>
						<div className={styles.headerWrapper}>
							<div>{title}</div>
							<Divider />
						</div>
						<div
							className={`${
								classNameCss ? styles.badgesNoWrapper : styles.badgesWrapper
							}`}
						>
							{array.map((ele: any) => (
								<div key={ele?.id}>
									<BadgeMobile
										title={`${ele?.code || ele?.name || ele?.title}`}
										isVisible={true}
										addArray={addArray}
										deleteArray={deleteArray}
										ele={ele}
										id={ele?.id}
										array={targetArray}
									/>
								</div>
							))}
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default FilterMobileItem;

import React, { useCallback, useEffect, useState, useContext } from "react";
import styles from "./filter-mobile.module.scss";
import Divider from "../common/divider/divider";
import BadgeMobile from "../common/badge-mobile/badge-mobile";

import { useRouter } from "next/router";
import { getParametersFromRouter } from "../../../pages/inventory/InventoryPage";
import { deliveryTypes } from "../translations/delivery-types";

interface FilterMobileDeliveryProps {
	handleChangeDelivery?: (value: string) => void;
}
function FilterMobileDelivery({
	handleChangeDelivery,
}: FilterMobileDeliveryProps): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [searchArrayFilter, setSearchArrayFilter] = useState<any[]>([]);
	const [searchArray, setSearchArray] = useState<string[]>([]);

	const router = useRouter();
	const queryParameters = getParametersFromRouter(router);

	useEffect(() => {
		setSearchArrayFilter([...deliveryTypes]);
	}, [deliveryTypes]);

	const addArray = (e: any) => {
		handleChangeDelivery && handleChangeDelivery(e.value);
		const arrayID = searchArray.map((ele: any) => (ele = ele.id));
		if (arrayID.includes(e.id)) return;
		setSearchArray([e]);
	};

	const deleteArray = (e: any) => {
		handleChangeDelivery && handleChangeDelivery("");
		let filter = searchArray.filter((ele: any) => ele.id !== e.id);
		setSearchArray(filter);
	};

	useEffect(() => {
		let result: any = [];
		result = deliveryTypes.filter(
			(ele) => ele.value === queryParameters.deliveryType,
		);

		setSearchArray(result);
	}, [router]);

	return (
		<>
			<div className={styles.filterSection}>
				<div
					className={styles.headerWrapper}
					onClick={() => setIsVisible(!isVisible)}
				>
					<div>{"Delivery Type"}</div>
					<Divider />
				</div>

				<div className={styles.badgesWrapper}>
					{searchArrayFilter.map((ele: any, i: number) => (
						<BadgeMobile
							key={i}
							title={`${ele?.name || ele?.title}`}
							isVisible={true}
							addArray={addArray}
							deleteArray={deleteArray}
							ele={ele}
							id={ele?.id}
							array={searchArray}
							type={true}
						/>
					))}
				</div>
			</div>
		</>
	);
}

export default FilterMobileDelivery;
